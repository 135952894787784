import React, {useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';
import {color_list} from '../../constants';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
//   subType: string;
//   setSubType: (item: string) => void;
// }

const Select_ColorPalette = () => { //: React.FC<IListProps>
  const [color, setColor] = useState<string>('radio-light-blue');
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  return (
    <div className="select_color_Grid">
      <div className="dropdown_wrapper">
        <div
          role="button"
          className=" dropdown_btn"
          onClick={() => {
            toggleDropdown();
          }}>
          <i className={`${color} selected_color_pallet`}></i>
          <i className="dropdown_arrow">
            <FaChevronDown />
          </i>
        </div>
        {showDropdown && (
          <div className="dropdown_content">
            {color_list.map((item, index) => (
              <input
                key={index}
                onClick={() => setColor(item)}
                type="radio"
                name="radio-4"
                className={`radio radio-accent ${item}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select_ColorPalette;
