import React, {
  RefObject,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {useThree, useFrame, ThreeEvent} from '@react-three/fiber';
import {Html, TransformControls as DreiTransformControls} from '@react-three/drei';
import * as THREE from 'three';
import {FaSync, FaTrash} from 'react-icons/fa';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {Vector3} from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {TransformControls as ThreeTransformControls} from 'three/examples/jsm/controls/TransformControls';

interface Block {
  id: string;
  url: string;
  position: [number, number, number];
  rotation: [number, number, number];
  gltf: THREE.Group;
  description: string;
}

interface InteractiveGLBModelProps {
  url: string;
  position: [number, number, number];
  rotation: [number, number, number];
  gltf: THREE.Group;
  onPositionChange: (newPosition: [number, number, number]) => void;
  onRotationChange: (newPosition: [number, number, number]) => void;
  isSelected: boolean;
  onSelect: () => void;
  setIsOrbitEnabled: (enabled: boolean) => void;
  onDelete: () => void;
  onDuplicate: () => void;
  // onRotate: (axis: 'x' | 'z' | 'y') => void;
  callBlockPosition: (newPosition: [number, number, number]) => void;
  modelFiles: Block[];
  orbitControlsRef: RefObject<OrbitControls> | ((instance: any) => void);
  setSelectedBlock: () => void;
}
type Mode = 'rotate' | 'scale' | 'translate' | undefined;

// Initialize GLTFLoader
const loader = new GLTFLoader();
const scene = new THREE.Scene();

const InteractiveGLBModel: React.FC<InteractiveGLBModelProps> = React.memo(
  ({
    url,
    position,
    rotation,
    gltf,
    onPositionChange,
    onRotationChange,
    isSelected,
    onSelect,
    setIsOrbitEnabled,
    onDelete,
    onDuplicate,
    // onRotate,
    callBlockPosition,
    modelFiles,
    orbitControlsRef,
    setSelectedBlock,
  }) => {
    const ref = useRef<THREE.Group>(null);
    const rotationRef = useRef<[number, number, number]>([0, 0, 0]);
    // const menuRef = useRef<HTMLDivElement>(null);
    const {camera, gl} = useThree();
    const [isDragging, setIsDragging] = useState(false);
    const [mode, setMode] = useState<Mode>("translate");
    const [controls, setControls] = useState<OrbitControls | null>(null);
    const dragStart = useRef<THREE.Vector3>(new THREE.Vector3());
    const dragPlane = useRef<THREE.Plane>(new THREE.Plane());
    const clickStartTime = useRef<number>(0);
    const blockPositionRef = useRef<[number, number, number]>([0, 0, 0]);

    // // Clone and enable transparency for materials
    // useEffect(() => {
    //   gltf.traverse(child => {
    //     if ((child as THREE.Mesh).isMesh) {
    //       const mesh = child as THREE.Mesh;
    //       if (mesh.material) {
    //         if (Array.isArray(mesh.material)) {
    //           mesh.material = mesh.material.map(mat => {
    //             const clonedMat = mat.clone();
    //             clonedMat.transparent = isSelected;
    //             clonedMat.opacity = isSelected ? 0.3 : 1;
    //             // clonedMat.transparent = isHovered || isSelected;
    //             // clonedMat.opacity = isHovered || isSelected ? 0.3 : 1;
    //             return clonedMat;
    //           });
    //         } else {
    //           const clonedMat = mesh.material.clone();
    //           clonedMat.transparent = isSelected;
    //           clonedMat.opacity = isSelected ? 0.3 : 1;
    //           // clonedMat.transparent = isHovered || isSelected;
    //           // clonedMat.opacity = isHovered || isSelected ? 0.3 : 1;
    //           mesh.material = clonedMat;
    //         }
    //       }
    //     }
    //   });
    // }, [isSelected, gltf]);

    const onPointerDown = useCallback(
      (e: ThreeEvent<PointerEvent>) => {
        e.stopPropagation();
        onSelect();
        // clickStartTime.current = Date.now();

        // if (ref.current) {
        //   dragStart.current.copy(ref.current.position);
        //   const normal = camera.getWorldDirection(new THREE.Vector3());
        //   dragPlane.current.setFromNormalAndCoplanarPoint(
        //     normal,
        //     ref.current.position,
        //   );
        // }

        // gl.domElement.addEventListener('pointermove', onPointerMove);
        // gl.domElement.addEventListener('pointerup', onPointerUp);
      },
      [onSelect, gl, camera],
    );

    const onPointerMove = useCallback(
      (e: PointerEvent) => {
        if (Date.now() - clickStartTime.current > 200) {
          setIsDragging(true);
          setIsOrbitEnabled(false);
          gl.domElement.style.cursor = 'grabbing';
        }
      },
      [setIsOrbitEnabled, gl],
    );

    const onPointerUp = useCallback(
      (e: PointerEvent) => {
        // gl.domElement.removeEventListener('pointermove', onPointerMove);
        // gl.domElement.removeEventListener('pointerup', onPointerUp);
        // setIsOrbitEnabled(true);
        // setIsDragging(false);
        // if (isDragging) {
        //   callBlockPosition(blockPositionRef.current);
        //   gl.domElement.style.cursor = 'auto';
        // }
      },
      [isDragging, setIsOrbitEnabled, gl],
    );

    const handleOptionClick = useCallback(
      (action: 'delete' | 'duplicate' | 'rotateX' | 'rotateY' | 'rotateZ') => {
        switch (action) {
          case 'delete':
            onDelete();
            break;
          case 'duplicate':
            onDuplicate();
            break;
          // case 'rotateX':
          //   onRotate('x');
          //   break;
          // case 'rotateY':
          //   onRotate('z');
          //   break;
          // case 'rotateZ':
          //   onRotate('z');
          //   break;
        }
      },
      [], //onDelete, onDuplicate, onRotate
    );

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        // if (
        //   menuRef.current &&
        //   !menuRef.current.contains(event.target as Node) &&
        //   ref.current &&
        //   !ref.current.getObjectByProperty(
        //     'uuid',
        //     (event.target as HTMLElement).dataset.uuid,
        //   )
        // ) {
        //   setShowMenu(false);
        // }
        if (!mode) {
          setSelectedBlock();
        }
      };
      const handleKeyPress = (event: KeyboardEvent) => {
        // Only handle key events if this component is selected
        if (!isSelected) return;

        // Prevent default behavior for these keys
        if (['r', 'R', 'e', 'E', 'Delete', 'Backspace'].includes(event.key)) {
          event.preventDefault();
        }

        switch (event.key) {
          case 'R':
          case 'r':
            onSelect();
            setMode('rotate');
            break;
          case 'E':
          case 'e':
            onSelect();
            setMode('translate');
            break;
          case 'Delete':
          case 'Backspace':
            handleOptionClick('delete');
            break;
          default:
            break;
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, [isSelected, mode, onSelect, handleOptionClick]); // Add dependencies here

    useFrame(({raycaster, mouse}) => {
      if (isDragging && ref.current) {
        raycaster.setFromCamera(mouse, camera);
        const intersection = new THREE.Vector3();

        if (raycaster.ray.intersectPlane(dragPlane.current, intersection)) {
          const newPosition: [number, number, number] = [
            Math.round(intersection.x),
            Math.round(intersection.y),
            Math.round(intersection.z),
          ];
          onPositionChange(newPosition);
          blockPositionRef.current = newPosition;
          // setBlockPosition(newPosition);
        }
      }
    });

    // // Assuming that each child point for snapping has specific characteristics
    // loader.load('/models/3749.glb', gltf1 => {
    //   // yellow - it's dragging piece
    //   const model1 = gltf1.scene;
    //   scene.add(model1);

    //   loader.load('/models/41239.glb', gltf2 => {
    //     // black - it's not dragging piece
    //     const model2 = gltf2.scene;
    //     scene.add(model2);

    //     // Define snapping threshold distance
    //     const SNAPPING_THRESHOLD = 1;

    //     // Initialize an array to hold the world positions of snap points and their names
    //     const snapPointWorldPositions = model2.children
    //       .filter(child =>
    //         [
    //           'AnchorSquare145',
    //           'AnchorSquare146',
    //           'AnchorSquare147',
    //           'AnchorSquare148',
    //           'AnchorSquare149',
    //           'AnchorSquare150',
    //           'AnchorSquare151',
    //           'AnchorSquare152',
    //           'AnchorSquare153',
    //           'AnchorSquare154',
    //           'AnchorSquare155',
    //           'AnchorSquare156',
    //           'AnchorSquare157',
    //         ].includes(child.name),
    //       )
    //       .map(child => {
    //         const worldPos = new THREE.Vector3();
    //         child.getWorldPosition(worldPos);
    //         return worldPos;
    //       });

    //     if (ref.current && ref.current.position) {
    //       let closestSnapPoint: THREE.Vector3 | null = null;
    //       // Loop through each snap point to find the closest within the threshold
    //       snapPointWorldPositions.forEach(point => {
    //         const distance =
    //           (ref.current && ref.current.position.distanceTo(point)) || 0;
    //         if (distance <= SNAPPING_THRESHOLD) {
    //           closestSnapPoint = point;
    //         }
    //       });
    //       if (closestSnapPoint) {
    //         ref.current.position.copy(closestSnapPoint);
    //       }
    //     }
    //   });
    // });

    const urls:string[] = [];
    const snapPointNames:string[] = [];
    modelFiles.map((element) => {
      const gltfs = element.gltf?.children
        .filter((item) => item.name.includes('Anchor'))
        .map((item) => item.name);
      urls.push(element.url);
      snapPointNames.push(...gltfs);
    });
  
    interface Config {
      modelPaths: string[];
      snapPointNames: string[];
      snappingThreshold: number;
    }

    let config: Config = {
      modelPaths: urls,
      snapPointNames: snapPointNames,
      snappingThreshold: 1,
    }

    function loadModel(loader: any, path: string) {
      return new Promise((resolve, reject) => {
        loader.load(
          path,
          (gltf: any) => resolve(gltf.scene),
          undefined,
          reject,
        );
      });
    }
    function extractSnapPoints(model: any, snapPointCriteria: any) {
      if (model && model.children) {
        return model.children
          .filter((child: any) => snapPointCriteria(child))
          .map((child: any) => {
            const worldPos = new THREE.Vector3();
            child.getWorldPosition(worldPos);
            return worldPos;
          });
      }
    }
    async function setupModels(scene: any, loader: any, config: Config) {
      try {
        const models = await Promise.all(
          config.modelPaths.map((path: any) => loadModel(loader, path)),
        );

        models.forEach((model: any) => scene.add(model));

        const snapModel = models[Math.abs(models.length - 2)];

        const snapPointCriteria = (child: any) =>
          config.snapPointNames.includes(child.name);

        const snapPointWorldPositions = extractSnapPoints(
          snapModel,
          snapPointCriteria,
        );

        if (ref.current && ref.current.position) {
          // let closestDistance = Infinity;
          let closestSnapPoint = null;

          snapPointWorldPositions?.forEach((point: any) => {
            const distance = ref?.current?.position.distanceTo(point) || 0;
            if (
              // distance < closestDistance &&
              distance <= config.snappingThreshold
            ) {
              closestSnapPoint = point;
              // closestDistance = distance;
            }
          });

          // if (closestSnapPoint) {
          //   console.log("closestSnapPoint", closestSnapPoint);
          //   ref.current.position.copy(closestSnapPoint);
          // }
        }
      } catch (error) {
        console.error('Error loading models:', error);
      }
    }
    setupModels(scene, loader, config);

    useEffect(() => {
      let controls: OrbitControls | null = null;
      if (typeof orbitControlsRef !== 'function') {
        if (orbitControlsRef.current) {
          controls = orbitControlsRef.current;
          setControls(orbitControlsRef.current);
        }
      }
    }, [orbitControlsRef]);

    return isSelected ? (
      <DreiTransformControls
        size={0.5}
        mode={mode}
        onMouseDown={() => {
          if (controls) {
            controls.enabled = false; // Disable controls on mouse down
          }
        }}
        onMouseUp={() => {
          if (controls) {
            if (mode === 'translate') {
              onPositionChange(blockPositionRef.current);
              callBlockPosition(blockPositionRef.current);
            } else if (mode === 'rotate') {
              onRotationChange(rotationRef.current);
            }
            controls.enabled = true; // Re-enable controls on mouse up
          }
        }}
        onChange={event => {
          if (event && event.target) {
            const target = event.target as unknown as ThreeTransformControls;
            if (ref.current && target && target.object) {
              // Get position
              const worldPosition = new THREE.Vector3();
              ref.current.getWorldPosition(worldPosition);
              blockPositionRef.current = [
                Math.round(worldPosition.x * 100) / 100,
                Math.round(worldPosition.y * 100) / 100,
                Math.round(worldPosition.z * 100) / 100,
              ];

              // Get rotation
              const transformedObject = target.object;
              const rotation_position = transformedObject.rotation;

              // Update rotation reference directly (in radians)
              rotationRef.current = [
                rotation_position.x,
                rotation_position.y,
                rotation_position.z,
              ];
            }
          }
        }}
        position={position}
        rotation={rotation}>
        <group ref={ref} onPointerDown={onPointerDown}>
          <primitive object={gltf} />
        </group>
      </DreiTransformControls>
    ) : (
      <group
        ref={ref}
        position={position}
        rotation={rotation}
        onPointerDown={onPointerDown}
        // onPointerOver={() => setIsHovered(true)}
        // onPointerOut={() => setIsHovered(false)}
      >
        <primitive object={gltf} />
      </group>
    );
  },
);

export default InteractiveGLBModel;
