import React from 'react';
import {SelectRotation} from '../custom-select/index';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
// }

const Light_7 = () => { //: React.FC<IListProps> = ({id, dropdownOpen, isDropdownOpen}) => {
  return (
    <div className="main-shape shape-purple">
      <div className="shape">
        <i className="shape-grid-icon"></i>
      </div>
      <div className="sub_model_box">
        <span className="sub_model_turn_on">Rotate</span>
        <SelectRotation
        // id={id}
        // dropdownOpen={dropdownOpen}
        // isDropdownOpen={isDropdownOpen}
        />
      </div>
    </div>
  );
};

export default Light_7;
