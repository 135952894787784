import React from 'react';
import {DragSourceMonitor, useDrag} from 'react-dnd';
import {IListProps} from './List';

let id = 1;
interface IListData {
  id: number;
  category: React.ReactNode;
}

enum ItemTypes {
  Card = 'card',
}

const Box: React.FC<IListData & IListProps> = ({
  category,
  cardList,
  changeCardList,
}) => {
  const box = {
    category,
  };
  const [, drag] = useDrag({
    type: ItemTypes.Card,
    item() {
      const useless = cardList.find((item: IListData) => item.id === -1);
      if (!useless) {
        changeCardList([{category: 'Put here', id: -1}, ...cardList]);
      }
      return box;
    },
    end(_: unknown, monitor: DragSourceMonitor) {
      const uselessIndex = cardList.findIndex(
        (item: IListData) => item.id === -1,
      );

      if (monitor.didDrop()) {
        cardList.splice(uselessIndex, 1, {...monitor.getItem(), id: id++});
      } else {
        cardList.splice(uselessIndex, 1);
      }
      changeCardList(cardList);
    },
  });
  return <div ref={drag}>{category}</div>;
};

export default Box;
