import React, {useRef, useEffect, Suspense} from 'react';
import {Canvas, useFrame} from '@react-three/fiber';
import {OrbitControls, Box, Cylinder} from '@react-three/drei';
import {Physics, useBox, usePlane} from '@react-three/cannon';
import {GRID_UNIT, BRICK_HEIGHT, STUD_SIZE} from '../constants';
import * as THREE from 'three';

interface Block {
  type: string;
  position: [number, number, number];
  color: string;
}

interface PlayModalProps {
  blocks: Block[];
  onClose: () => void;
}

const PhysicsBlock: React.FC<{block: Block}> = ({block}) => {
  const [ref, api] = useBox(() => ({
    mass: 1,
    position: block.position,
    args: getGeometryArgs(block.type),
    angularVelocity: [
      Math.random() * 10 - 5,
      Math.random() * 10 - 5,
      Math.random() * 10 - 5,
    ],
  }));

  useFrame(() => {
    api.position.subscribe(position => {
      if (position[1] <= -9.9) {
        api.angularVelocity.set(0, 0, 0);
      }
    });
  });

  const renderStuds = () => {
    const dimensions = getGeometryArgs(block.type);
    const [width, height, depth] = dimensions;
    const studs = [];
    const studCountX = width / GRID_UNIT;
    const studCountZ = depth / GRID_UNIT;
    const isPlate = block.type.includes('plate');
    const studHeight = isPlate ? STUD_SIZE * 0.8 : STUD_SIZE;
    const blockHeight = isPlate ? BRICK_HEIGHT / 3 : BRICK_HEIGHT;

    for (let x = 0; x < studCountX; x++) {
      for (let z = 0; z < studCountZ; z++) {
        studs.push(
          <Cylinder
            key={`stud-${x}-${z}`}
            args={[STUD_SIZE / 2, (STUD_SIZE / 2) * 1.1, studHeight, 16]}
            position={[
              x * GRID_UNIT - width / 2 + GRID_UNIT / 2,
              blockHeight / 2 + studHeight / 2,
              z * GRID_UNIT - depth / 2 + GRID_UNIT / 2,
            ]}>
            <meshStandardMaterial color={block.color} />
          </Cylinder>,
        );
      }
    }
    return studs;
  };

  return (
    <group ref={ref as React.RefObject<THREE.Group>}>
      <Box args={getGeometryArgs(block.type)}>
        <meshStandardMaterial color={block.color} />
      </Box>
      {renderStuds()}
    </group>
  );
};

const Floor: React.FC = () => {
  const [ref] = usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    position: [0, -10, 0],
  }));
  return (
    <mesh ref={ref as React.RefObject<THREE.Mesh>} receiveShadow>
      <planeGeometry args={[1000, 1000]} />
      <meshStandardMaterial color="#cccccc" />
    </mesh>
  );
};

const PlayModal: React.FC<PlayModalProps> = ({blocks, onClose}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}>
      <div
        className="bg-white p-4 rounded-lg w-3/4 h-3/4 relative"
        onClick={e => e.stopPropagation()}>
        <button
          className="btn btn-circle btn-sm absolute top-2 right-2 z-10"
          onClick={handleClose}>
          ✕
        </button>
        <Canvas camera={{position: [0, 5, 10], fov: 75}}>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <OrbitControls
            enablePan={false}
            enableZoom={true}
            enableRotate={true}
          />
          <Physics>
            <Floor />
            <Suspense fallback={null}>
              {blocks.map((block, index) => (
                <PhysicsBlock key={index} block={block} />
              ))}
            </Suspense>
          </Physics>
        </Canvas>
      </div>
    </div>
  );
};

const getGeometryArgs = (type: string): [number, number, number] => {
  switch (type) {
    case '1x1':
      return [GRID_UNIT, BRICK_HEIGHT, GRID_UNIT];
    case '2x2':
      return [2 * GRID_UNIT, BRICK_HEIGHT, 2 * GRID_UNIT];
    case '2x3':
      return [2 * GRID_UNIT, BRICK_HEIGHT, 3 * GRID_UNIT];
    case '2x4':
      return [2 * GRID_UNIT, BRICK_HEIGHT, 4 * GRID_UNIT];
    case '1x2 plate':
      return [GRID_UNIT, BRICK_HEIGHT / 3, 2 * GRID_UNIT];
    case '1x4 plate':
      return [GRID_UNIT, BRICK_HEIGHT / 3, 4 * GRID_UNIT];
    default:
      return [GRID_UNIT, BRICK_HEIGHT, GRID_UNIT];
  }
};

export default PlayModal;
