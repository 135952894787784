export const GRID_UNIT = 1;
export const STUD_SIZE = 0.25 * GRID_UNIT;
export const BRICK_HEIGHT = 0.3 * GRID_UNIT;
export const PLATE_HEIGHT = 0.1 * GRID_UNIT;

export const LEGO_COLORS = [
  '#FF0000', // Bright Red
  '#FFD700', // Bright Yellow
  '#1E90FF', // Bright Blue
  '#32CD32', // Lime Green
  '#FFA500', // Orange
  '#8A2BE2', // Blue Violet
  '#00CED1', // Dark Turquoise
  '#FF1493', // Deep Pink
];


export const ACTIONS_LIST = [
  // {
  //   name: 'Motors',
  //   color: 'shape-blue',
  // },
  // {
  //   name: 'Movement',
  //   color: 'shape-pink',
  // },
  {
    name: 'Light',
    color: 'shape-purple',
  },
  // {
  //   name: 'Sound',
  //   color: 'shape-lightpurple',
  // },
  // {
  //   name: 'Events',
  //   color: 'shape-yellow',
  // },
  // {
  //   name: 'Control',
  //   color: 'shape-darkyellow',
  // },
  // {
  //   name: 'Sensors',
  //   color: 'shape-lightblue',
  // },
  // {
  //   name: 'Operators',
  //   color: 'shape-green',
  // },
  // {
  //   name: 'Variables',
  //   color: 'shape-orange',
  // },
  // {
  //   name: 'My blocks',
  //   color: 'shape-red',
  // },
];

export const ACTIONS_LIST_3 = [
  {
    name: 'Events',
    color: 'shape-yellow',
  },
  {
    name: 'Light',
    color: 'shape-purple',
  },
  {
    name: 'Sound',
    color: 'shape-lightpurple',
  },
  {
    name: 'Control',
    color: 'shape-darkyellow',
  },
];

export const color_list = [
  'radio-dark-blue',
  'radio-light-white',
  'radio-red',
  'radio-orange',
  'radio-yellow',
  'radio-dark-green',
  'radio-green',
  'radio-light-blue',
  'radio-blue',
  'radio-purple',
];