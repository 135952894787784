import React, {useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
// }

const SelectMulti_Grid = () => { //: React.FC<IListProps>
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  return (
    <div className="select_multi_Grid">
      <div className="dropdown_wrapper">
        <div role="button" className="dropdown_btn" onClick={toggleDropdown}>
          <i className="dropdown_icon">
            <i className="shape-grid-icon"></i>
          </i>
          <i className="dropdown_arrow">
            <FaChevronDown />
          </i>
        </div>
        {showDropdown && (
          <ul className="dropdown_content">
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item active"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item active"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item active"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item active"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item active"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item active"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item active"></span>
            </li>
            <li>
              <span className="select_multi_Grid_item"></span>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectMulti_Grid;
