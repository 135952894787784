import React, {useRef} from 'react';
import {OrbitControls} from '@react-three/drei';
import {Canvas, useThree} from '@react-three/fiber';
import GLBModel from '../components/GLBModel';

interface PlayModalProps {
  onClose: () => void;
}

const WordblocksPreview: React.FC<PlayModalProps> = ({onClose}) => {
  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg w-3/4 h-3/4 relative">
        <button
          className="btn btn-circle btn-sm absolute top-2 right-2 z-10"
          onClick={handleClose}>
          ✕
        </button>
        <Canvas camera={{position: [45, 30, 20], fov: 85}}>
          <Scene isAnimating={false} />
        </Canvas>
      </div>
    </div>
  );
};

const Scene: React.FC<{isAnimating: boolean}> = ({isAnimating}) => {
  const orbitControlsRef = useRef<any>(null);
  const {camera} = useThree();
  return (
    <>
      <OrbitControls ref={orbitControlsRef} camera={camera} enabled={true} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <GLBModel
        url={'/models/wordblocksPreview/SlideColorSensor.glb'}
        filename={'SlideColorSensor.glb'}
        animation={isAnimating}
        position={[0, -10, 0]}
      />
    </>
  );
};

export default WordblocksPreview;
