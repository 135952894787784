import update from 'immutability-helper';
import React, {CSSProperties, useCallback} from 'react';
import {useDrop} from 'react-dnd';
import Card from './Card';

const style: CSSProperties = {
  backgroundColor: 'white',
  border: '1px dashed gray',
  margin: '100px auto',
  minHeight: '300px',
  padding: '0 10px',
  textAlign: 'center',
  width: 300,
};

export interface IListProps {
  cardList: IListData[];
  changeCardList: (list: IListData[]) => void;
}
interface IListData {
  id: number;
  category: React.ReactNode;
}

enum ItemTypes {
  Card = 'card',
}

const List: React.FC<IListProps> = ({cardList, changeCardList}) => {
  const [, drop] = useDrop({
    accept: ItemTypes.Card,
  });

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === undefined) {
        const lessIndex = cardList.findIndex(
          (item: IListData) => item.id === -1,
        );
        changeCardList(
          update(cardList, {
            $splice: [
              [lessIndex, 1],
              [hoverIndex, 0, {category: 'Put here', id: -1}],
            ],
          }),
        );
      } else {
        const dragCard = cardList[dragIndex];
        changeCardList(
          update(cardList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        );
      }
      // eslint-disable-next-line
    },
    [cardList],
  );

  return (
    <div style={style} ref={drop} className='model_details_width'>
      {cardList.length <= 0 ? (
        <div style={{lineHeight: '60px'}}>Please put</div>
      ) : (
        cardList.map((item: IListData, index: number) => (
          <Card index={index} key={item.id} moveCard={moveCard} {...item} />
        ))
      )}
    </div>
  );
};

export default List;
