import React, { useState, useEffect, useRef } from 'react';

const TextInput: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('hello');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (inputRef.current) {
     
      inputRef.current.style.width = `${Math.max(inputValue.length, 1) + 2}ch`; // +2 for padding
    }
  }, [inputValue]);

  return (
    <input
      ref={inputRef}
      className="rounded_button"
      type="text"
      value={inputValue}
      onChange={handleChange}
       style={{ minWidth: '30px' }} 
    />
  );
};

export default TextInput;
