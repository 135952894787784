import React, {useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
//   subType: string;
//   setSubType: (item: string) => void;
//   index: number;
// }

const Select_Quantity = () => { //: React.FC<IListProps>
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  return (
    <div className="select_quantity_Grid">
      <div className="dropdown_wrapper">
        <div role="button" className=" dropdown_btn" onClick={toggleDropdown}>
          <i className="select_quantity_number">1</i>
          <i className="dropdown_arrow">
            <FaChevronDown />
          </i>
        </div>
        {showDropdown && (
          <ul className="dropdown_content">
            <li className="active">1</li>
            <li>2</li>
            <li>3</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select_Quantity;
