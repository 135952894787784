import React, {useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
// }

const Select_Word_filter = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  return (
    <div className="select_word_Grid">
      <div className="dropdown_wrapper">
        <div role="button" className=" dropdown_btn" onClick={toggleDropdown}>
          <i className="select_word_name">A</i>
          <i className="dropdown_arrow">
            <FaChevronDown />
          </i>
        </div>
        {showDropdown && (
          <ul className="dropdown_content">
            <li className="word_content_wrapper">
              <span className="select_word_name active">A</span>
              <span className="select_word_name">B</span>
              <span className="select_word_name">c</span>
              <span className="select_word_name">D</span>
              <span className="select_word_name">E</span>
              <span className="select_word_name">F</span>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select_Word_filter;
