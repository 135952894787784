import React, {useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';
import {GrRotateLeft, GrRotateRight} from 'react-icons/gr';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
// }

const Select_Rotation = () => { //: React.FC<IListProps>
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };
  return (
    <div className="select_rotation_Grid">
      {/* dropdown */}
      <div className="dropdown_wrapper">
        <div role="button" className=" dropdown_btn" onClick={toggleDropdown}>
          <i className="select_rotation_icon">
            <GrRotateRight />
          </i>
          <i className="dropdown_arrow">
            <FaChevronDown />
          </i>
        </div>
        {showDropdown && (
          <ul className="dropdown_content">
            <li>
              <span className="select_rotation_icon active">
                {' '}
                <GrRotateRight />
              </span>
            </li>
            <li>
              <span className="select_rotation_icon">
                <GrRotateLeft />
              </span>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select_Rotation;
