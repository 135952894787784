import React from 'react';
import {SelectWordFilter} from '../custom-select/index';
import {CgMusicNote} from 'react-icons/cg';

const Sound_1 = () => {
  return (
    <div className="main-shape shape-lightpurple">
      <div className="shape">
        <CgMusicNote size={20} />
      </div>
      <div className="sub_model_box">
        <span className="sub_model_turn_on">Play sound</span>
        <SelectWordFilter />
        <span className="font-bold">until done</span>
      </div>
    </div>
  );
};

export default Sound_1;
