import React from 'react';
import {Grid as DreiGrid} from '@react-three/drei';

const Grid: React.FC = () => {
  return (
    <>
      <DreiGrid
        infiniteGrid
        cellSize={1}
        sectionSize={10}
        fadeDistance={50}
        fadeStrength={1.5}
      />
      {/* <DreiGrid
        infiniteGrid
        cellSize={1}
        sectionSize={10}
        fadeDistance={50}
        fadeStrength={1.5}
        // rotation={[Math.PI / 2, 0, 0]}
      />
      <DreiGrid
        infiniteGrid
        cellSize={1}
        sectionSize={10}
        fadeDistance={50}
        fadeStrength={1.5}
        // rotation={[0, 0, Math.PI / 2]}
      /> */}
    </>
  );
};

export default Grid;
