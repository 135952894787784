import React from 'react';
import InputNumber from '../custom-input/Input-Number';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
// }

const Light_5 = () => { //: React.FC<IListProps> = ({id, dropdownOpen, isDropdownOpen}) => {
  return (
    <div className="main-shape shape-purple">
      <div className="shape">
        <i className="shape-grid-icon"></i>
      </div>
      <div className="sub_model_box">
        <span className="sub_model_turn_on">Set pixel brightness to</span>
        <InputNumber />
        <span className="font-bold">%</span>
      </div>
    </div>
  );
};

export default Light_5;
