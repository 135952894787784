import React from 'react';
import {SelectQuantity} from '../custom-select/index';
import InputNumber from '../custom-input/Input-Number';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
// }

const Light_6 = () => {
  //: React.FC<IListProps> = ({id, dropdownOpen, isDropdownOpen}) => {
  // const [subType, setSubType] = useState<string>('0');
  const quantities = [1, 2];
  return (
    <div className="main-shape shape-purple">
      <div className="shape">
        <i className="shape-grid-icon"></i>
      </div>
      <div className="sub_model_box">
        <span className="sub_model_turn_on">Set pixel at</span>
        {quantities.map((quantity, index) => (
          <React.Fragment key={index}>
            <SelectQuantity
            // id={id}
            // index={index}
            // subType={subType}
            // setSubType={setSubType}
            // dropdownOpen={dropdownOpen}
            // isDropdownOpen={isDropdownOpen}
            />
            {index < quantities.length - 1 && (
              <span className="font-bold">,</span>
            )}
          </React.Fragment>
        ))}
        <span className="font-bold">to</span>
        <InputNumber />
      </div>
    </div>
  );
};

export default Light_6;
