import React, {useRef, useMemo} from 'react';
import {XYCoord} from 'dnd-core';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import {RiListUnordered} from 'react-icons/ri';

interface IProps {
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}
interface IListData {
  id: number;
  category: React.ReactNode;
}

enum ItemTypes {
  Card = 'card',
}

const Card: React.FC<IListData & IProps> = ({
  category,
  index,
  moveCard,
  id,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{isDragging}, drag, dragPreview] = useDrag({
    type: ItemTypes.Card,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {index},
  });

  const [, drop] = useDrop({
    accept: ItemTypes.Card,
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current!.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      if (item.index !== undefined) {
        item.index = hoverIndex;
      }
    },
  });

  const style: React.CSSProperties = useMemo(
    () => ({
      position: 'relative',
      margin: '16px 6px',
      opacity: id === -1 ? 0.4 : isDragging ? 0.2 : 1,
      padding: '20px 0px',
      verticalAlign: 40,
      width: 288,
    }),
    [id, isDragging],
  );

  dragPreview(drop(ref));

  return (
    <div ref={ref} style={style} className='model_details_wrapper'>
      {id !== -1 &&
        drag &&
        drag(
          <div>
            <RiListUnordered />
          </div>,
        )}
      {category}
    </div>
  );
};

export default Card;
