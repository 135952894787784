import * as THREE from 'three';
import React, {useRef, useCallback, useState, useMemo} from 'react';
import {useThree, ThreeEvent, useFrame} from '@react-three/fiber';
import {Box, Cylinder, Html} from '@react-three/drei';
import {GRID_UNIT, STUD_SIZE, BRICK_HEIGHT} from '../constants';
import {FaPaintBrush, FaCopy, FaTrash} from 'react-icons/fa';

interface BlockProps {
  type: string;
  position: [number, number, number];
  onPositionChange: (newPosition: [number, number, number]) => void;
  isSelected: boolean;
  onSelect: () => void;
  blocks: {type: string; position: [number, number, number]; color: string}[];
  setIsOrbitEnabled: (enabled: boolean) => void;
  color: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onChangeColor: () => void;
}

const Block: React.FC<BlockProps> = ({
  type,
  position,
  onPositionChange,
  isSelected,
  onSelect,
  blocks,
  setIsOrbitEnabled,
  color,
  onDelete,
  onDuplicate,
  onChangeColor,
}) => {
  const ref = useRef<THREE.Group>(null);
  const {camera, gl} = useThree();
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const dragStart = useRef<THREE.Vector3>(new THREE.Vector3());
  const dragPlane = useRef<THREE.Plane>(new THREE.Plane());
  const clickStartTime = useRef<number>(0);
  const lastValidPosition = useRef<[number, number, number]>(position);

  const getGeometryArgs = (blockType: string): [number, number, number] => {
    switch (blockType) {
      case '1x1':
        return [GRID_UNIT, BRICK_HEIGHT, GRID_UNIT];
      case '2x2':
        return [2 * GRID_UNIT, BRICK_HEIGHT, 2 * GRID_UNIT];
      case '2x3':
        return [2 * GRID_UNIT, BRICK_HEIGHT, 3 * GRID_UNIT];
      case '2x4':
        return [2 * GRID_UNIT, BRICK_HEIGHT, 4 * GRID_UNIT];
      case '1x2 plate':
        return [GRID_UNIT, BRICK_HEIGHT / 3, 2 * GRID_UNIT];
      case '1x4 plate':
        return [GRID_UNIT, BRICK_HEIGHT / 3, 4 * GRID_UNIT];
      default:
        return [GRID_UNIT, BRICK_HEIGHT, GRID_UNIT];
    }
  };

  const dimensions = useMemo(() => getGeometryArgs(type), [type]);

  const offset = useMemo(() => {
    const [width, height, depth] = dimensions;
    return [width / 2, height / 2, depth / 2];
  }, [dimensions]);

  const adjustedPosition = useMemo(() => {
    return [
      position[0] + offset[0],
      position[1] + offset[1],
      position[2] + offset[2],
    ] as [number, number, number];
  }, [position, offset]);

  const onPointerDown = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      e.stopPropagation();
      onSelect();
      clickStartTime.current = Date.now();

      if (ref.current) {
        dragStart.current.copy(ref.current.position);
        const normal = camera.getWorldDirection(new THREE.Vector3());
        dragPlane.current.setFromNormalAndCoplanarPoint(
          normal,
          ref.current.position,
        );
      }

      gl.domElement.addEventListener('pointermove', onPointerMove);
      gl.domElement.addEventListener('pointerup', onPointerUp);
    },
    [onSelect, gl, camera],
  );

  const onPointerMove = useCallback(
    (e: PointerEvent) => {
      if (Date.now() - clickStartTime.current > 200) {
        setIsDragging(true);
        setIsOrbitEnabled(false);
        setShowMenu(false);
        gl.domElement.style.cursor = 'grabbing';
      }
    },
    [setIsOrbitEnabled, gl],
  );

  const onPointerUp = useCallback(
    (e: PointerEvent) => {
      gl.domElement.removeEventListener('pointermove', onPointerMove);
      gl.domElement.removeEventListener('pointerup', onPointerUp);

      if (isDragging) {
        setIsDragging(false);
        setIsOrbitEnabled(true);
        gl.domElement.style.cursor = 'auto';
      } else if (Date.now() - clickStartTime.current < 200) {
        setShowMenu(prev => !prev);
      }
    },
    [isDragging, setIsOrbitEnabled, gl],
  );

  const handleOptionClick = useCallback(
    (action: 'delete' | 'duplicate' | 'changeColor') => {
      switch (action) {
        case 'delete':
          onDelete();
          break;
        case 'duplicate':
          onDuplicate();
          break;
        case 'changeColor':
          onChangeColor();
          break;
      }
      setShowMenu(false);
    },
    [onDelete, onDuplicate, onChangeColor],
  );

  useFrame(({raycaster, mouse}) => {
    if (isDragging && ref.current) {
      raycaster.setFromCamera(mouse, camera);
      const intersection = new THREE.Vector3();
      if (raycaster.ray.intersectPlane(dragPlane.current, intersection)) {
        const newPosition: [number, number, number] = [
          Math.round(intersection.x - offset[0]),
          Math.round(intersection.y - offset[1]),
          Math.round(intersection.z - offset[2]),
        ];

        const isColliding = blocks.some((block, index) =>
          block.type !== type || block.position !== position
            ? isOverlapping(block, newPosition, dimensions)
            : false,
        );

        if (!isColliding) {
          onPositionChange(newPosition);
          lastValidPosition.current = newPosition;
        } else {
          // If there's a collision, revert to the last valid position
          onPositionChange(lastValidPosition.current);
        }
      }
    }
  });

  const isOverlapping = (
    block: {type: string; position: [number, number, number]},
    position: [number, number, number],
    dimensions: [number, number, number],
  ): boolean => {
    const [x1, y1, z1] = block.position;
    const [w1, h1, d1] = getGeometryArgs(block.type);
    const [x2, y2, z2] = position;
    const [w2, h2, d2] = dimensions;

    return (
      x1 < x2 + w2 &&
      x1 + w1 > x2 &&
      y1 < y2 + h2 &&
      y1 + h1 > y2 &&
      z1 < z2 + d2 &&
      z1 + d1 > z2
    );
  };

  const outlineMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        color: {value: new THREE.Color('yellow')},
      },
      vertexShader: `
        void main() {
          vec4 worldPosition = modelMatrix * vec4(position, 1.0);
          vec4 mvPosition = viewMatrix * worldPosition;
          gl_Position = projectionMatrix * mvPosition;
        }
      `,
      fragmentShader: `
        uniform vec3 color;
        void main() {
          gl_FragColor = vec4(color, 1.0);
        }
      `,
      side: THREE.BackSide,
    });
  }, []);

  const renderStuds = () => {
    const [width, height, depth] = dimensions;
    const studs = [];
    const studCountX = width / GRID_UNIT;
    const studCountZ = depth / GRID_UNIT;
    const isPlate = type.includes('plate');
    const studHeight = isPlate ? STUD_SIZE * 0.8 : STUD_SIZE;
    const blockHeight = isPlate ? BRICK_HEIGHT / 3 : BRICK_HEIGHT;

    for (let x = 0; x < studCountX; x++) {
      for (let z = 0; z < studCountZ; z++) {
        studs.push(
          <Cylinder
            key={`stud-${x}-${z}`}
            args={[STUD_SIZE / 2, (STUD_SIZE / 2) * 1.1, studHeight, 16]}
            position={[
              x * GRID_UNIT - width / 2 + GRID_UNIT / 2,
              blockHeight / 2 + studHeight / 2,
              z * GRID_UNIT - depth / 2 + GRID_UNIT / 2,
            ]}>
            <meshStandardMaterial
              color={isSelected || isHovered ? '#FFFF00' : color}
            />
          </Cylinder>,
        );
      }
    }
    return studs;
  };

  return (
    <group
      ref={ref}
      position={adjustedPosition}
      onPointerDown={onPointerDown}
      onPointerOver={() => setIsHovered(true)}
      onPointerOut={() => setIsHovered(false)}>
      {(isSelected || isHovered) && (
        <Box
          args={[
            dimensions[0] + 0.05,
            (type.includes('plate') ? BRICK_HEIGHT / 3 : BRICK_HEIGHT) + 0.05,
            dimensions[2] + 0.05,
          ]}
          position={[
            0,
            (type.includes('plate') ? -BRICK_HEIGHT / 3 : 0) / 2,
            0,
          ]}>
          <primitive object={outlineMaterial} attach="material" />
        </Box>
      )}
      <Box
        args={[
          dimensions[0],
          type.includes('plate') ? BRICK_HEIGHT / 3 : BRICK_HEIGHT,
          dimensions[2],
        ]}
        castShadow
        receiveShadow
        position={[0, (type.includes('plate') ? -BRICK_HEIGHT / 3 : 0) / 2, 0]}>
        <meshStandardMaterial color={color} />
      </Box>
      {renderStuds()}
      {showMenu && !isDragging && (
        <Html position={[0, dimensions[1] + 0.5, 0]}>
          <div className="flex space-x-2" onClick={e => e.stopPropagation()}>
            <button
              className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md"
              onClick={() => handleOptionClick('changeColor')}>
              <FaPaintBrush className="text-gray-600" />
            </button>
            <button
              className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md"
              onClick={() => handleOptionClick('duplicate')}>
              <FaCopy className="text-gray-600" />
            </button>
            <button
              className="w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md"
              onClick={() => handleOptionClick('delete')}>
              <FaTrash className="text-gray-600" />
            </button>
          </div>
        </Html>
      )}
    </group>
  );
};

export default Block;
