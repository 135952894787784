import React, {useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';
import {RiLoader3Fill} from 'react-icons/ri';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
//   subType: string;
//   setSubType: (item: string) => void;
// }

const SelectLoader_Grid = () => { //: React.FC<IListProps>
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  return (
    <div className="select_loader_Grid">
      <div className="dropdown_wrapper">
        <div role="button" className=" dropdown_btn" onClick={toggleDropdown}>
          <div className="flex gap-[3px]">
            <i className="rotation_icon">
              <RiLoader3Fill />
            </i>
            <i className="rotation_icon active">
              <RiLoader3Fill />
            </i>
          </div>

          <i className="dropdown_arrow">
            <FaChevronDown />
          </i>
        </div>
        {showDropdown && (
          <ul className="dropdown_content">
            <li>
              <span className="rotation_icon active">
                <RiLoader3Fill />
              </span>
            </li>
            <li>
              <span className="rotation_icon">
                <RiLoader3Fill />
              </span>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectLoader_Grid;
