import React from 'react';
import {SelectColorPalette} from '../custom-select/index';

// interface IListProps {
//   id: number;
//   dropdownOpen: (item: any) => void;
//   isDropdownOpen: number;
// }

const Light_8 = () => {
  //: React.FC<IListProps> = ({id, dropdownOpen, isDropdownOpen}) => {
  // const [subType, setSubType] = useState<string>('0');
  return (
    <div className="main-shape shape-purple">
      <div className="shape">
        <i className="shape-grid-icon"></i>
      </div>
      <div className="sub_model_box">
        <span className="sub_model_turn_on">Set Power Button light to</span>
        <SelectColorPalette
        // id={id}
        // subType={subType}
        // setSubType={setSubType}
        // dropdownOpen={dropdownOpen}
        // isDropdownOpen={isDropdownOpen}
        />
      </div>
    </div>
  );
};

export default Light_8;
