import React from 'react';
import {SelectWordFilter, SelectColorPalette} from '../custom-select/index';
import {IoRadioButtonOn} from 'react-icons/io5';

const Event_1 = () => {
  return (
    <div className="main-shape shape-yellow">
      <div className="shape">
        <IoRadioButtonOn size={20} />
      </div>
      <div className="sub_model_box">
        <SelectWordFilter />
        <span className="font-bold">When color is</span>
        <SelectColorPalette />
      </div>
    </div>
  );
};

export default Event_1;
