import React, { useState, useEffect, useRef } from 'react';

const InputNumber: React.FC = () => {
  const [inputValue, setInputValue] = useState<number | string>(0); // Allow inputValue to be number or string
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value === '' ? '' : parseFloat(value)); // Allow empty string for user input
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = `${Math.max(inputValue.toString().length, 1) + 2}ch`; 
    }
  }, [inputValue]);

  return (
    <input
      ref={inputRef}
      className="rounded_button "
      type="number"
      value={inputValue}
      onChange={handleChange}
      style={{ minWidth: '30px' }}
      placeholder="0" 
    />
  );
};

export default InputNumber;
