import React from 'react';
import {SelectColorPalette} from '../custom-select/index';
import {PiHandPalm} from 'react-icons/pi';

const Control_1 = () => {
  return (
    <div className="main-shape shape-darkyellow">
      <div className="shape">
        <PiHandPalm size={20} />
      </div>
      <div className="sub_model_box">
        <span className="sub_model_turn_on">Stop</span>
        <SelectColorPalette />
      </div>
    </div>
  );
};

export default Control_1;
